:root{
  --gradient:linear-gradient(90deg, #4a519c, #1b71ec);
  --app_color_main:#1b71ec;
  --app_color_sec:#4a519c;
}
/* all the document properties */
*{
  font-family: 'M PLUS Rounded 1c', sans-serif;
  margin:0;padding: 0;
  box-sizing: border-box;
  border: none; outline: none;
  text-decoration: none;
  text-transform: capitalize;
  transition: all .24s linear;
  direction: rtl;
}

*::selection{
  background: var(--app_color_main);
  color: #fff;
}

html{
  font-size: 62.5%;
  overflow-x: hidden;
}

html::-webkit-scrollbar{
  width: 1.5rem;
  direction: rtl;
}

html::-webkit-scrollbar-track{
  background: #333;
}

html::-webkit-scrollbar-thumb{
  background:linear-gradient(var(--app_color_sec), var(--app_color_main));
}

section{
  min-height: 100vh;
  padding: 1rem 7%;
  padding-top: 6rem;
}






      /* for desktop */
      .whatsapp_float {
        position: fixed;
        width: 70px;
        height: 70px;
        bottom: 120px;
        left: 40px;
        background-color: #25d366;
        color: #FFF;
        border-radius: 50px;
        text-align: center;
        font-size: 40px;
        box-shadow: 2px 2px 3px #999;
        z-index: 100;
    }

    .whatsapp-icon {
      margin-top: 16px;
  }

    /* whatsapp icon mobile */
    @media screen and (max-width: 767px) {
      .whatsapp-icon {
          margin-top: 10px;
      }

      .whatsapp_float {
          width: 40px;
          height: 40px;
          bottom: 20px;
          left: 10px;
          font-size: 22px;
      }
  }

    .call_float {
      position: fixed;
      width: 70px;
      height: 70px;
      bottom: 30px;
      left: 40px;
      background-color: var(--app_color_main);
      color: #FFF;
      border-radius: 50px;
      text-align: center;
      font-size: 40px;
      box-shadow: 2px 2px 3px #999;
      z-index: 100;
  }

    .phone-icon {
        margin-top: 16px;
    }

    /* call icon mobile */
    @media screen and (max-width: 767px) {
        .phone-icon {
            margin-top: 10px;
        }

        .call_float {
            width: 40px;
            height: 40px;
            bottom: 70px;
            left: 10px;
            font-size: 25px;
        }
    }










.about_page{
  min-height: 60vh;
  padding: 1rem 7%;
  padding-top: 20rem;
}


.btn{
  width: 100%;
  padding: .7rem 1.9rem;
  font-size: 2rem;
  color:#fff;
  background: var(--gradient);
  border-radius: 5rem;
  margin-top: 1rem;
  cursor: pointer;
  box-shadow: 0 .1rem .3rem rgba(0, 0, 0, 0.3);
  text-align: center;
  position: relative;
  overflow: hidden;
  z-index: 0;
}

.btn::before{
  content: '';
  position: absolute;
  top: -1rem; left:-100%;
  background: #fff9;
  height: 150%;
  width: 20%;
  transform: rotate(25deg);
  z-index: -1;
}

.btn:hover::before{
  transition: .3s linear;
  left: 120%;
}

.heading{
  color: #444;
  padding: 1rem;
  font-size: 4rem;
  text-align: center;
}
body{
  height: 300rem;
}

header{
  width: 100%;
  display:flex;
  align-items: center;
  justify-content: space-between;
  position: fixed;
  top: 0; left: 0;
  z-index: 1000;
  padding: 1.5rem 7%;
  background: var(--gradient);
  box-shadow: 0 .1rem .3rem rgba(0, 0, 0, 0.3);

}

header .logo{
  color:#fff ;
  font-size:2rem;
  padding-left: 2rem;

}

header .logo img{
  position: relative;
  height: 5rem;
  top:0;

}

header .navbar ul{
  display:flex;
  align-items: center;
  justify-content: center;
  list-style: none;
}

header .navbar ul li{
  margin:0 1rem;
}

header .navbar ul li a{
  font-size: 2rem;
  color:#fff;
}

header .navbar ul li a.active,
header .navbar ul li a:hover{
  color: #444;
}

header #login {
  font-size: 3rem;
  color:#fff;
  cursor: pointer;
}

header #login:hover {
  color: #444;
}

header #menu{
  font-size: 3rem;
  color:#fff;
  cursor: pointer;
  display: none;
}


.login-form{
  height: 100%;
  width: 100%;
  position: fixed;
  top:-120%; left:0;
  z-index: 1000;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
}

.login-form.popup{
  top:0;

}


.login-form form{
  width: 35rem;
  background: #fff;
  margin: 0 2rem;
  padding: 1rem 3rem;
  border-radius: 0.5rem;
  box-shadow: 0 .1rem .3rem #333;
  position: relative;
}

.login-form form h3{
  font-size: 3rem;
  color: var(--app_color_main);
  padding:1rem 0;
}

.login-form form .box{
  width: 100%;
  padding: 1rem;
  margin:1rem 0;
  border-bottom: .2rem solid #666;
  font-size: 1.6rem;
  color: #333;
  text-transform: none;
}

.login-form form .box::placeholder{
  text-transform: capitalize;

}

.login-form form .box:focus{
  border-color: var(--app_color_main);
}

.login-form form .box:nth-child(3){
  margin-bottom: 5rem;
}

.login-form form p{
  font-size: 1.4rem;
  color: #666;
  padding: .5rem 0;
}
.login-form form p a{
  color:var(--app_color_main);

}

.login-form form.btn{
  width: 100%;
  margin: 2rem 0;
}

.login-form form .btn:hover{
  background: #444;
}

.login-form form .fa-times{
  position: absolute;
  top: 1.5rem; left:1.5rem;
  font-size: 2.5rem;
  cursor: pointer;
  color: #999;

}

.login-form form .fa-times:hover{
  color: #444;
}

.login-form form .fa-times{
  position: absolute;
  top:1.5rem; left: 1.5rem;
  font-size: 2.5rem;
  color: #999;
}


.home{
  display: flex;
  align-items: center;
  justify-content: center;
  flex-flow: column;
  background: url(./images/back.jpeg) center center no-repeat fixed;
  background-size: cover;
  background-position: center;
  background-attachment: fixed;
  position: relative;
  text-align: center;
}

@supports (-webkit-touch-callout: none) {
  .home {
      
      background: url(./images/back.jpeg) center top no-repeat scroll;
      background-size: auto 110vh;
  }

  .home .home_pic img, .home h1, .home p{
    position: relative;
      bottom: 3vh; 
  }
}
  /* .home .home_pic{
    flex: 1 1 40rem;
  } */
  .home .home_pic img{
    height: 30vh;
    /* width: 100%; */
  }

.home h1 {
  font-size: 6rem;
  color: #666;
}
.home p {
  font-size: 1.9rem;
  color: #999;
  padding: 1rem 0;
  width: 70rem;
}


.home .shape{
  position: absolute;
  bottom: -.2rem; left: 0;
  height: 15rem;
  width: 100%;
  background: url(./images/shape.png);
  block-size: 100rem 15rem;
  filter: drop-shadow(0 -.1rem .1rem rgba(0,0,0,.2));
  animation: animate 60s linear infinite;
}

@keyframes animate{
  0%{
      background-position-x: 0;
  }
  100%{
      background-position-x: 1000rem;
  }
}


.about{
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
}

.about .image{
  flex: 1 1 40rem;
}
.about .image img{
  
  width: 100%;
  padding: 3vh;
  
}


.about .content{
  flex: 1 1 40rem;
}

.about .content h3{
  font-size: 3.5rem;
  color: #666;
}

.about .content p{
  font-size: 1.5rem;
  color: #666;
  padding: 1rem 0;
}

.course .box-container{
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;


}

.course .box-container .box{
  width: 35rem;
  border: .1rem solid rgba(0, 0, 0, 0.3);
  position: relative;
  margin: 1.5rem;
}

.course .box-container .box img {
  height: 20rem;
  width: 100%;
  object-fit: cover;
}

.course .box-container .box .content {
  padding: 1rem;
}

.course .box-container .box .content .stars{
  color: var(--app_color_main);
  font-size: 1.7rem;
  padding: 1rem .1rem;
}

.course .box-container .box .content .title{
  color: #444;
  font-size: 2.1rem;

}

.course .box-container .box .content .title:hover{
  text-decoration: underline;
}

.course .box-container .box .content p{
  padding: 1rem 0;
  color: #666;
  font-size: 1.5rem;
}

.course .box-container .box .content .info{
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-top: .5rem;
  border-top: .1rem solid rgba(0, 0, 0, 0.3);
}
/* 
.course .box-container .box a{
  font-size: 1.5rem;
  padding: 1rem;
  color: #666;
} */

.course .box-container .box .content .info h3{
  font-size: 1.5rem;
  padding: 1rem;
  color: #666;
}

.course .box-container .box .content .info i{
  color: var(--app_color_main);
  padding: .35rem;
}


.course .box-container .box .more {
  position: absolute;
  top: 16rem; left: 1rem;
  height: 8rem;
  width: 8rem;
  line-height: 8rem;
  text-align: center;
  border-radius: 50%;
  background: var(--gradient);
  color: #fff;
  font-size: 2rem;
  cursor: pointer;
}

.course .box-container .box .more:hover{
  background:#444;
}


.teacher{
  text-align: center;
  background: url(./images/pencils.png);
  background-size: cover;
  background-position: bottom;

}


.teacher p{
  font-size: 1.8rem;
  color: #666;
  width: 65rem;
  margin: 1rem auto;
}

.contact {
  background: #111;
}

.contact .heading{
  color: #fff;
}

.contact .row{
  display: flex;
  align-items: flex-start;
  justify-content: center;
  flex-wrap: wrap;
}

.contact .row form{
  flex: 1 1 50rem;
  background: #222;
  padding: 2rem;
  display:flex;
  justify-content: space-between;
  flex-wrap: wrap;
  margin-top: 3rem;
  align-items: flex-end;
}

.contact .row form .box{
  height: 5rem;
  width:49%;
  border: .1rem solid #eee;
  padding: 0 1rem;
  margin:1rem 0;
  font-size: 1.7rem;
  color: #fff;
  background: none;
  text-transform: none;
}

.contact button{
  right: 48%;
  width: 50%;
  margin: 2rem 0;
  padding: 1.8rem 0rem;
  align-items: flex-end;
}

.contact .row form .box:focus{
  border-color: var(--app_color_main);
}

.contact .row form .box::placeholder{
  text-transform: capitalize;

}

.contact .row form .address{
  height: 15rem;
  padding: 1rem;
  resize: none;
  width: 100%;
}

.contact .row form .btn:hover{
  background: #fff;
  color: #333;
}

.contact .row .image img{
  height: 80vh;
  margin-top: 2rem;

}


.footer{
  background: #333;

}

.footer .box-container{
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
}

.footer .box-container .box{
  margin: 1.5rem;
  text-align: center;
  flex: 1 1 25rem;
}

.footer .box-container .box h3{
  font-size: 2.5rem;
  color: #fff;
  padding: 1rem 0;
}

.footer .box-container .box p{
  font-size: 1.7rem;
  color: #eee;
  padding: .7rem 0;
}

.footer .box-container .box p i{

  padding-left: .5rem;
  color: var(--app_color_main);
}


.footer .box-container .box a{
  font-size: 1.8rem;
  display: block;
  padding: .5rem 0;
  color: #eee;
}

.footer .box-container .box a:hover{
  text-decoration: underline;
}

.footer .credit {
  padding: 2rem 1rem;
  font-size: 2rem;
  color: #fff;
  background: #111;
  text-align: center;
  font-weight: normal;
}

.footer .credit a{
  color: var(--app_color_main);
}

.about_page{
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
}

.about_page .image{
  flex: 1 1 40rem;
}
.about_page .image img{
  height: 43vh;
  width: 100%;
}


.about_page .content{
  flex: 1 1 40rem;
}

.about_page .content h1{
  font-size: 6rem;
  color: #666;
}

.about_page .content p{
  font-size: 2rem;
  color: #666;
  padding: 1rem 0;
}

.return_back{
  display: flex;
  align-items: center;
  justify-content: left;
  flex-flow: column;
  position: relative;
}
.return_back .btn{
  width: 15rem;
  font-size: 3rem
}









/* media queries */

@media(max-width:768px){

  html{
      
      font-size: 55%;
      
  }

  header #menu{
      display:block;
  }

  header .navbar{
      position: fixed;
      top:-120%; left:0;
      width:100%;
      background: #444;
      transition:0.50s linear;
  }
  header .navbar ul{
      flex-flow: column;
      padding: 2rem 0;
  }

  header .navbar ul li{
      margin:1.5rem 0;
      text-align: center;
      width: 100%;
  }

  header .navbar ul li a{
      font-size: 2.5rem;
      display: block;
  }

  header .navbar ul li a.active,
  header .navbar ul li a:hover{
      color: var(--app_color_main);

  }

  .fa-times{
      transform: rotate(180deg);
      transition: all .35s linear;
  }

  header .navbar.nav-toggle{
      top:6.4rem;
      transition:0.50s linear;
  }

  .home h1{
      font-size: 4rem;
  }
  .home p{
      width: auto;
  }

  .teacher p{
      width: auto;
  }

  .contact .row .image{
      display: none;
  }

  .home .home_pic img, .home h1, .home p{
    position: relative;
      bottom: 9vh; 
  }

}


@media(max-width:500px){

  html{
      font-size: 50%;
  }

  .contact .row form .box{
      width: 100%;
  }
}


  /* for desktop */
  .facebookLink_float {
    position: fixed;
    width: 70px;
    height: 70px;
    bottom: 210px;
    left: 40px;
    background-color: #4267B2;
    color: #FFF;
    border-radius: 50px;
    text-align: center;
    font-size: 40px;
    box-shadow: 2px 2px 3px #999;
    z-index: 100;
}

  .facebookLink-icon {
    margin-top: 15px;
}

  /* facebookLink icon mobile */
  @media screen and (max-width: 767px) {
    .facebookLink-icon {
        margin-top: 10px;
    }

    .facebookLink_float {
        width: 40px;
        height: 40px;
        bottom: 120px;
        left: 10px;
        font-size: 22px;
    }
}
